<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll-nav ref="nav" current="基本信息" v-if="detail">
        <cube-scroll-nav-panel key="基本信息" label="基本信息">
          <inputItem title="订单编码" v-model="detail.code" :disabled="true"></inputItem>
          <inputItem title="下单时间" :initialContent="formatDate(detail.sale_time, 'yyyy-MM-dd hh:mm')" :disabled="true"></inputItem>
          <inputItem v-if="detail.category == '平台订单'" title="合同单号" v-model="detail.business_code" :disabled="disabled"></inputItem>
          <inputItem title="订单类型" v-model="detail.category" :disabled="disabled || (orderType != 1 && orderType != 2)" type="select" :options="categoryOption"></inputItem>
          <inputItem title="供货单位" v-model="detail.owner" :disabled="disabled || (orderType != 1 && orderType != 2 && orderType != 5)" type="select" :options="ownerOption"></inputItem>
          <inputItem title="业务部门" v-model="detail.dept" :disabled="disabled || (orderType != 1 && orderType != 2 && orderType != 5)" type="select" :options="deptOption"></inputItem>
          <!-- <inputItem title="分摊比例" :initialContent="deptRatio(detail.ratio)" :disabled="disabled || (orderType != 1 && orderType != 2 && orderType != 5)" name="ratio" type="select" :options="ratioOption" :contentChanged="contentChanged"></inputItem> -->
          <inputItem title="业务代表" v-model="detail.user_name" :disabled="true"></inputItem>
          <inputItem v-if="(detail.type == 3 ||detail.type == 4 ||detail.type == 5 ||detail.type == 6)" title="项目名称" :initialContent="detail.project_name" :disabled="disabled"></inputItem>
          <inputItem title="客户名称" :initialContent="detail.customer_name" :disabled="disabled" type="others" ref="customer_name" :otherClicked="clickCutomer"></inputItem>
          <inputItem v-if="(detail.type == 1 || detail.type == 2)" title="联系人员" v-model="detail.linkman" :disabled="disabled" ref="linkman"></inputItem>
          <inputItem v-if="(detail.type == 1 || detail.type == 2)" title="联系电话" v-model="detail.link_tel" :disabled="disabled" ref="link_tel"></inputItem>
          <inputItem v-if="(detail.type == 1 || detail.type == 2)" title="收货地址" v-model="detail.shipping_address" :disabled="disabled"></inputItem>
          <inputItem title="订单备注" v-model="detail.remark" :disabled="disabled"></inputItem>
          <itemTotal :total="total"></itemTotal>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="供货明细" label="供货明细">
          <div class="mobile-detail-list">
            <div class="list-item" v-for="(item, index) in items" :key="index">
              <div class="item-header">
                <div class="item-index">商品{{ index + 1 }}</div>
                <img class="item-delete" src="@/static/images/icon_delete.png" alt @click="deleteItem(index)" v-if="!disabled" />
              </div>
              <div class="item-line">
                <div class="item-lable"> 商品名称 </div>
                <div class="item-value"> {{ item.goods_name }} </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 销售数量 </div>
                  <input class="input-right" v-model="item.num" :disabled="disabled" @input="changeNum(item)" />
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 销售单价 </div>
                  <input class="input-right" v-model="item.price" :disabled="disabled" @input="changeNum(item)" />
                </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 预计成本 </div>
                  <input class="input-right" v-model="item.pre_cost" :disabled="disabled" />
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 销售金额 </div>
                  <input class="input-right" v-model="item.total" :disabled="disabled" @input="changeTotal(item)" />
                </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 预计毛利 </div>
                  <input class="input-right" v-model="item.pre_profit" :disabled="disabled" />
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 预计毛利率 </div>
                  <input class="input-right" v-model="item.pre_rate" :disabled="disabled" />
                </div>
              </div>
              <div class="item-line">
                <div class="item-lable"> 商品备注 </div>
                <input class="input-left" v-model="item.remark" :disabled="disabled" />
              </div>
            </div>
          </div>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="相关附件" label="相关附件">
          <uploadBox v-model="attachs" :allowed="buttons && (buttons.includes('保存草稿') || buttons.includes('保存附件'))"> </uploadBox>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="审批流程" label="审批流程" v-if="flows && flows.flowLogs && flows.flowLogs.length > 0">
          <div class="mobile-detail-step">
            <Steps :current="flows.step" :status="flows.status" direction="vertical">
              <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName" :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
            </Steps>
          </div>
        </cube-scroll-nav-panel>
      </cube-scroll-nav>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
    <optionDrawer ref="cutomerDrawer" v-if="showCutomers" :onBgClicked="onBgClicked" :onSearch="onSearchCutomer" :items="cutomers" @optionClicked="cutomerClicked"></optionDrawer>
  </div>
</template>

<script>
import { GetSale, SaveSale, AuditSale, CancelSale, SaveAttach } from "@/api";
import { saleState } from "@/utils/state.js";
import { formatDate, formatMoney } from "@/utils/format.js";
import { loadDetailAction, submitAction, auditAction, cancelAction, loadDataOptionAction, loadCustomerOptionAction, saveAttach } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      items: [],
      flows: [],
      attachs: [],
      buttons: [],
      total: {
        total: null,
        cost: null,
        profit: null,
        rate: null,
        comix_tariff: null,
        comix_price: null,
      },
      disabled: false,
      categoryOption: [],
      ownerOption: [],
      deptOption: [],
      ratioOption: [],
      cutomers: [],
      showCutomers: false,
      projects: [],
      showProjects: false,
      orderType: 0
    };
  },
  methods: {
    saleState: saleState,
    formatDate: formatDate,
    formatMoney: formatMoney,
    contentChanged: function (index, value) {
      switch (index) {
        case "ratio":
          this.detail.ratio = value;
          break;
      }
    },
    deleteItem: function (index) {
      this.items.splice(index, 1);
      this.setTotal();
    },
    changeNum: function (item) {
      item.total = item.price * item.num;
      this.setTotal();
    },
    changeTotal: function (item) {
      item.price = item.total / item.num;
      this.setTotal();
    },
    setTotal: function () {
      var tot = this.total;
      tot.total = 0;
      tot.cost = 0;
      tot.profit = 0;
      tot.rate = 0;
      tot.comix_tariff = 0;
      this.items.forEach(function (item) {
        tot.comix_tariff = Number(item.comix_tariff);
        tot.total += Number(item.total);
        tot.cost += Number(item.pre_cost);
      });
      tot.comix_price = (tot.total * tot.comix_tariff) / 100;
      tot.profit = tot.total - tot.cost - tot.comix_price;
      tot.rate = tot.total == 0 ? 0 : ((tot.profit * 100) / tot.total).toFixed(2);
    },
    deptRatio: function (value) {
      var res = this.ratioOption.filter(c => c.value == value);
      return (res && res.length > 0) ? res[0].text : "";
    },
    onSearchCutomer: function (value) {
      loadCustomerOptionAction(this, value, (data) => { this.cutomers = data; });
    },
    onBgClicked: function () {
      this.showCutomers = false;
    },
    cutomerClicked: function (item) {
      this.detail.customer_id = item.id;
      this.detail.customer_name = item.name;
      this.detail.linkman = item.exts[0];
      this.detail.link_tel = item.exts[1];
      this.$refs.customer_name.setContent(item.name);
      this.$refs.linkman.setContent(item.exts[0]);
      this.$refs.link_tel.setContent(item.exts[1]);
      this.showCutomers = false;
    },
    clickCutomer: function () {
      this.showCutomers = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.cutomerDrawer.show();
        }, 100);
      });
    },
    buttonClick: function (title) {
      switch (title) {
        case "保存草稿":
          this.saveSale(1);
          break;
        case "提交审核":
          this.saveSale(2);
          break;
        case "审核通过":
          this.auditSale(true, "同意");
          break;
        case "审核拒绝":
          this.auditSale(false, "");
          break;
        case "撤回":
          this.cancelSale();
          break;
        case "保存附件":
          this.saveAttach();
          break;
      }
    },
    saveSale: function (state) {
      this.detail.state = state;
      submitAction(this, SaveSale, { model: this.detail, items: this.items }, () => { this.$router.go(-1); }, () => { this.detail.state = 1; }, (state == 2), "是否确定提交？");
    },
    auditSale: function (pass, memo) {
      auditAction(this, AuditSale, this.id, pass, memo);
    },
    cancelSale: function () {
      cancelAction(this, CancelSale, this.id);
    },
    saveAttach: function () {
      saveAttach(this, SaveAttach, { pid: this.detail.id, attachs: this.attachs });
    },
    dataRequest: function () {
      loadDetailAction(this, GetSale, { id: this.id, type: this.orderType }, (data) => {
        this.detail = data.list[0].model;
        this.items = data.list[0].items;
        this.flows = data.list[0].flows;
        this.attachs = data.list[0].attachs;
        this.buttons = data.list[0].buttons;
        this.orderType = this.detail.type;
        this.disabled = !this.buttons || this.buttons.indexOf("保存草稿") < 0;
        this.setTotal();
        this.$nextTick(() => {
          this.$refs.nav.refresh();
        });
      });
    }
  },
  mounted() {
    loadDataOptionAction(this, "销售单分类", (data) => { this.categoryOption = data; });
    loadDataOptionAction(this, "业务归属", (data) => { this.ownerOption = data; });
    loadDataOptionAction(this, "业务部门", (data) => { this.deptOption = data; });
    loadDataOptionAction(this, "分摊比例", (data) => { this.ratioOption = data; }, true);

    if (this.$route.query["type"]) {
      this.orderType = parseInt(this.$route.query["type"]);
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>